import { useContext } from "react"
import { Context } from "../Components/Context"
import axios from "axios"

const useAlertWizard = () => {
  const [state, setState] = useContext(Context)

  const setAlertPreferences = (prefs) => {
    setState( {...state, ...{alertPreferences: prefs} })
  }

  const setStores = (stores) => {
    setState( {...state, ...{stores: stores} })
  }

  const setCustomDeal = (param) => {

    if (typeof(param.store_id) !== "undefined")
    {
      let foundStore = state.stores.filter(store => {
        return store.id === param.store_id
      })
      param = {...param, store_name: foundStore[0].name}
    }
    setState( {...state, ...{customDealSelection: param} })
  }

  return {
    alertPreferences: state.alertPreferences,
    stores: state.stores,
    setAlertPreferences,
    setStores,
    customDealSelection: state.customDealSelection,
    setCustomDeal
  }
}

export default useAlertWizard
