import React, { useState, useEffect } from "react"
import axios from "axios"
import './Alerts.scss'
import StoreAlert from "./StoreAlert.js"
import { Form, Icon, Input, Checkbox, Steps, Button, message, Spin } from 'antd'
import { navigate } from "gatsby"
import useAlertWizard from "../../Hooks/useAlertWizard"

function Alerts() {
  const { alertPreferences, setStores, stores } = useAlertWizard();
  const FormItem = Form.Item;
  const [isLoaded, setLoaded] = useState(false)
  const [email, setEmail] = useState("")
  const [current, setCurrent] = useState(0)
  const [saving, setSaving] = useState(false)
  const [confirmation, setConfirmation] = useState("")
  const [steps, setSteps] = useState([
    {
      title: 'Choose Your Alerts',
      content: 'First-content',
    },
    {
      title: 'Email address',
      content: 'Second-content',
    },
    {
      title: 'Confirmation',
      content: 'Last-content',
    },
  ])

  const { Step } = Steps;

  const next = () => {
    setCurrent(current+1)

    if (current === 1)
      save()
  }

  const prev = () => {
    setCurrent(current-1)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const save = () => {

    setSaving(true)

    axios.post("/api/contacts", {
      "contact":
      {
        "email" : email,
        "subscriptions_attributes": alertPreferences
      }
    }
    )
    .then(function (response) {
      console.log(response);
      setSaving(false)
      setConfirmation("You're now signed up for alerts. Get ready to save more.")
    })
    .catch(function (error) {
      console.log(error);
      setSaving(false)
      setConfirmation("Oops. Something went wrong. Back up and try again.")
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("/api/stores")
      setStores(result.data)
      setLoaded(true)
    }
    fetchData()
  }, [])

  return (
    <div className="Main Alerts">
      <div className="pure-g">
        <div className="pure-u-24-24">
          <h1> Sign up for Alerts </h1>

        </div>
      </div>
      <div>
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">
            {current == 0 && (
              <div>
                <div className="custom-user-alerts">
                  <div>
                    <ul>
                      {isLoaded ? (
                        stores ? (
                          <StoreAlert data={stores}></StoreAlert>
                        ) : (
                          <p>There was an issue loading stores.</p>
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {current == 1 && (
              <Form className="email-form">
                <FormItem>
                  <Input prefix={<Icon type="mail" />} placeholder="Email address" value={email} onChange={handleEmailChange} />
                </FormItem>
              </Form>
            )}
            {current == 2 && saving == false && (
              <div>
                <h3>Success!</h3>
                {confirmation}
              </div>
            )}
            {current == 2 && saving == true && (
              <div>
                <h3>Saving...</h3>
                <Spin size="large" tip="Loading..." ></Spin>
              </div>
            )}
          </div>
          <div className="steps-action">
            {current > 0 && current != steps.length - 1 && (
              <Button style={{ marginLeft: 8 }} onClick={() => prev()}>
                Back ({steps[current - 1].title})
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next ({steps[current + 1].title})
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={() => navigate("/")}>
                Done
              </Button>
            )}
          </div>
      </div>

    </div>
  )
}

export default Alerts
