import React, { useState, useCallback } from "react"
import "./StoreAlert.scss"
import { Button, Select } from 'antd'
import useAlertWizard from "../../Hooks/useAlertWizard";

const StoreAlert = (props) => {

  const { setAlertPreferences, alertPreferences, setCustomDeal, customDealSelection } = useAlertWizard();
  const { Option } = Select;
  const defaultValues = {
                          store_name: "Abercrombie & Fitch",
                          discount_value: "10% and up",
                          discount_type: "Everything on Sale",
                        }

  // const [customDealSelection,setCustomDeal] = useState(defaultValues)

  const handleClick = useCallback((value) => {
    setAlertPreferences([...alertPreferences, customDealSelection ])
  },[customDealSelection, alertPreferences])

  const discountTypeChange = useCallback((value) => {
    setCustomDeal({...customDealSelection,...{discount_type: value}})
  },[customDealSelection, alertPreferences])

  const discountValueChange = useCallback((value) => {
    setCustomDeal({...customDealSelection,...{discount_value: value}})
  },[customDealSelection, alertPreferences])

  const storeChange = useCallback((value) => {
    setCustomDeal({...customDealSelection,...{store_id: value}})
  },[customDealSelection, alertPreferences])

  return (
    <div>

      <div className="custom-user-alerts">
        <h4>
          My Selected Alerts
        </h4>
        <div className="selected-alerts">
          { alertPreferences.length === 0 && (
            <p>
              No alerts selected. Add alerts below
            </p>
          )
          }
          { alertPreferences.length > 0 && (
            alertPreferences.map(item => <li>{item.store_name} -
              {item.discountType} -
              {item.discount_value} </li>)
            )
          }
        </div>
      </div>
      <h4>
          Add New Alerts
      </h4>

      <li className="Store">
      <div className="Store-Name pure-u-7-24">
        <Select value={customDealSelection.store_name} style={{ width: 220 }} onChange={storeChange}>
          {
            props.data.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)
          }
        </Select>
      </div>
      <div className="Store-Frequency pure-u-9-24">
        <div className="Store-Selection">
          <div className="pure-u-12-24">
            <Select className="discount-type" value={customDealSelection.discount_type} style={{ width: 180 }} onChange={discountTypeChange}>
              <Option key="1" value="Everything on Sale">Everything on Sale</Option>
              <Option key="2" value="Regular Priced Items">Regular Priced Items</Option>
              <Option key="3" value="Clearance">Clearance</Option>
              <Option key="4" value="All Sales">All Sales</Option>
            </Select>
          </div>
          <div className="pure-u-3-24">
          <Select className="discount-value" value={customDealSelection.discount_value} onChange={discountValueChange} style={{ width: 180 }}>
              <Option key="1" value="10% off and up">10% off and up</Option>
              <Option key="2" value="15% off and up">15% off and up</Option>
              <Option key="3" value="20% off and up">20% off and up</Option>
              <Option key="4" value="25% off and up">25% off and up</Option>
              <Option key="5" value="30% off and up">30% off and up</Option>
              <Option key="6" value="35% off and up">35% off and up</Option>
            </Select>
          </div>
        </div>
      </div>
      <div className="Store-Button-Add pure-u-5-24">
        <Button type="primary" onClick={handleClick} icon="plus">
          Add
        </Button>
      </div>
  </li>
    </div>
  )
}

export default StoreAlert
