import React from "react"
import Layout from "../Components/Layout"
import { ContextProvider } from "../Components/Context"
import Body from '../Components/Alerts/Body'

const AlertPage = () => {
  return (
    <Layout pageClassName="IndexPage">
      <ContextProvider>
        <Body />
      </ContextProvider>
    </Layout>
  )
}

export default AlertPage
